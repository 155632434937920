import { useMemo, useState } from 'react';
import useSWR, { mutate } from 'swr';
// utils
import axios, { fetcher, endpoints, axiosInstance } from 'src/utils/axios';
// types
import {
  EventConnectMatch,
  IConnectRequest,
  IConnectRequestResponse,
  ICreateChannel,
  IEventParticipant,
  IParticipation,
} from 'src/types';
import { Pagination, PaginationQuery } from 'src/types/pagination';
import { toQueryParams } from 'src/utils';

export function useGetAttendees(id: string, query?: string) {
  const URL = query ? `${endpoints.connect.list(id)}?${query}` : endpoints.connect.list(id);

  const { data, isLoading, error } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      attendeesList: (data?.results as any[]) || [],
      attendeesLoading: isLoading,
      attendeesError: error,
      attendeesListEmpty: !isLoading && !data?.results?.length,
    }),
    [data, error, isLoading]
  );

  return memoizedValue;
}

export function useGetLikes() {
  const URL = endpoints.connect.likes;

  const { data, isLoading, error } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      likesList: (data?.results as any[]) || [],
      likesLoading: isLoading,
      likesError: error,
      likesListEmpty: !isLoading && !data?.results?.length,
    }),
    [data, error, isLoading]
  );

  return memoizedValue;
}

export function useGetLikesCount(isAuthenticated: boolean) {
  const URL = isAuthenticated ? endpoints.connect.likesCount : '';

  const { data, isLoading, error } = useSWR(URL, fetcher, {
    keepPreviousData: true,
    revalidateOnFocus: true,
  });

  const memoizedValue = useMemo(
    () => ({
      likesCounts: (data as any) || null,
      likesCountsLoading: isLoading,
      likesCountsError: error,
      likesCountsEmpty: !isLoading && !data,
    }),
    [data, error, isLoading]
  );

  return memoizedValue;
}

export function useGetMatches() {
  const URL = endpoints.connect.matches.getMatches;

  const {
    data,
    isLoading,
    error,
    mutate: mutateMatches,
  } = useSWR(URL, fetcher, {
    keepPreviousData: false,
    revalidateIfStale: true,
  });

  const memoizedValue = useMemo(
    () => ({
      matchesList: (data?.results as EventConnectMatch[]) ?? [],
      matchesLoading: isLoading,
      matchesError: error,
      matchesEmpty: !isLoading && !data,
      mutateMatches,
    }),
    [data, error, isLoading, mutateMatches]
  );

  return memoizedValue;
}

export const useSendLike = (): {
  sendLike: (id: string, request: IConnectRequest) => Promise<IConnectRequestResponse | undefined>;
  loading: boolean;
  liked: boolean;
} => {
  const [loading, setLoading] = useState(false);
  const [liked, setLiked] = useState(false);

  const sendLike = async (id: string, request: IConnectRequest) => {
    setLoading(true);
    let response: IConnectRequestResponse | undefined;
    try {
      const URL = endpoints.connect.request(id);
      const { data } = await axios.post(URL, request);
      response = data;
      mutate(URL, true);
      setLoading(false);
      setLiked(true);
    } catch (error) {
      console.error('Error:', error);
    }
    return response;
  };

  return { sendLike, loading, liked };
};

export const useRevealIdentity = (): {
  revealMyIDentity: (matchId: string, reveal: boolean) => Promise<any>;
  loading: boolean;
  isReveal: boolean;
} => {
  const [loading, setLoading] = useState(false);
  const [isReveal, setIsReveal] = useState(false);

  const revealMyIDentity = async (matchId: string, reveal: boolean) => {
    setLoading(true);
    let response: any;
    try {
      const URL = endpoints.connect.matches.showAvatar(matchId);
      const { data } = await axios.put(URL, { showAvatar: reveal });
      response = data;
      mutate('/messages', true);
      setLoading(false);
      setIsReveal(true);
    } catch (error) {
      console.error('Error:', error);
    }
    return response;
  };

  return { revealMyIDentity, loading, isReveal };
};

// export const useCreateChannel = (): {
//   createTrybeChannel: (matchId: string, channelId: string) => Promise<any>;
//   loading: boolean;
//   channelCreated: boolean;
// } => {
//   const [loading, setLoading] = useState(false);
//   const [channelCreated, setChannelCreated] = useState(false);

//   const createTrybeChannel = async (matchId: string, channelId: string) => {
//     setLoading(true);
//     let response: IConnectRequestResponse | undefined;
//     try {
//       const URL = endpoints.connect.channel(matchId);
//       const { data } = await axios.post(URL, { channelId });
//       response = data;
//       mutate(URL, true);
//       setLoading(false);
//       setChannelCreated(true);
//     } catch (error) {
//       console.error('Error:', error);
//     }
//     return response;
//   };

//   return { createTrybeChannel, loading, channelCreated };
// };

export const useSendParticipation = (): {
  sendParticipation: (id: string, request: IParticipation) => Promise<void>;
  loading: boolean;
} => {
  const [loading, setLoading] = useState(false);

  const sendParticipation = async (id: string, request: IParticipation) => {
    setLoading(true);

    try {
      const URL = endpoints.connect.participate(id);
      await axios.post<IEventParticipant>(URL, request);
      mutate(endpoints.event.detail(id));
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return { sendParticipation, loading };
};

export const useDisconnect = (): {
  disconnect: (matchId: string, channelId: string) => Promise<void>;
  loading: boolean;
} => {
  const [loading, setLoading] = useState(false);

  const disconnect = async (matchId: string, channelId: string) => {
    setLoading(true);

    try {
      const URL = endpoints.connect.matches.unMatch(matchId);
      await axios.post(URL, matchId);
      mutate('/messages/');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return { disconnect, loading };
};

export async function createTrybeChannel(
  payload: ICreateChannel
): Promise<IConnectRequestResponse> {
  const URL = endpoints.connect.channel(payload.matchId);
  const { data } = await axiosInstance.post<IConnectRequestResponse>(URL, payload);
  return data;
}

export async function getMatchesList(
  params?: PaginationQuery
): Promise<Pagination<EventConnectMatch>> {
  const URL = endpoints.connect.matches.getMatches;
  const { data } = await axiosInstance.get<Pagination<EventConnectMatch>>(
    `${URL}?${toQueryParams(params)}`
  );
  return data;
}

// export function useGetMatches() {
//   const URL = endpoints.connect.matches.getMatches;

//   const {
//     data,
//     isLoading,
//     error,
//     mutate: mutateMatches,
//   } = useSWR(URL, fetcher, {
//     keepPreviousData: false,
//     revalidateIfStale: true,
//   });

//   const memoizedValue = useMemo(
//     () => ({
//       matchesList: (data?.results as EventConnectMatch[]) ?? [],
//       matchesLoading: isLoading,
//       matchesError: error,
//       matchesEmpty: !isLoading && !data,
//       mutateMatches,
//     }),
//     [data, error, isLoading, mutateMatches]
//   );

//   return memoizedValue;
// }
