import { Event, Offer, WithContext } from 'schema-dts';

import { IEvent } from '../types';
import { CurrencyCodes } from '../types/users/currency';

export function parseDescription(description: string | null): string {
  if (!description) {
    return '';
  }

  try {
    const parsed = JSON.parse(description) as any;
    if (parsed.blocks) {
      return parsed.blocks
        .map((block: any) => {
          if (block.type === 'paragraph') {
            return block.data.text;
          }
          if (block.type === 'list') {
            return block.data.items.join(', ');
          }
          return '';
        })
        .join(' ');
    }
    return '';
  } catch (e) {
    return description;
  }
}

function getOffers(event: IEvent): Offer[] {
  if (!event?.ticketSettings?.length) {
    return [];
  }
  return event.ticketSettings
    .filter((ticket) => ticket.quantityLeft > 0)
    .map(
      (ticket) =>
        ({
          '@type': 'Offer',
          name: ticket.title,
          price: ticket.totalPrice,
          priceCurrency: event?.currency || CurrencyCodes.CAD,
          validFrom: event.startDate as unknown as string,
          validThrough: event.endDate as unknown as string,
          url: `https://trybe.fm/event/${event.id}`,
        }) as Offer
    );
}

export function getEventStructuredData(event: IEvent, description: string): WithContext<Event> {
  return {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: event?.title,
    ...(event?.startDate && { startDate: event.startDate as unknown as string }),
    ...(event?.endDate && { endDate: event.endDate as unknown as string }),
    url: `https://trybe.fm/event/${event.id}`,
    location: {
      '@type': 'Place',
      name: event.venue?.name,
      address: {
        '@type': 'PostalAddress',
        addressCountry: event?.venue?.address?.country,
        addressRegion: event?.venue?.address?.region,
        streetAddress: event?.venue?.address?.formattedAddress,
        postalCode: event.venue.address?.postalCode,
        name: event.venue?.name,
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: event?.venue?.latitude,
        longitude: event?.venue?.longitude,
      },
    },
    image: [event?.cover?.url],
    description,
    offers: getOffers(event),
    organizer: {
      '@type': 'Organization',
      name: event.organizationId ? `${event.organization?.name}` : `${event.organizer?.name}`,
    },
  } as WithContext<Event>;
}
