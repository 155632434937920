import { isEmpty } from 'lodash';

type QueryParamValue =
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[]
  | { [key: string]: any };

function serialize(param: { [key: string]: any } | undefined): string {
  if (typeof param === 'object' && param !== null) {
    return Object.entries(param)
      .map(([key, value]) => {
        if (typeof value === 'object') {
          return `${key}[${serialize(value)}]`;
        }
        return `${key}=${encodeURIComponent(value.toString())}`;
      })
      .filter((part) => part !== '')
      .join('&');
  }
  return '';
}

export function toQueryParams<T>(params: T): string {
  const casted = params as { [key: string]: QueryParamValue | undefined };
  if (isEmpty(casted)) {
    return '';
  }

  return Object.entries(casted)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => {
      if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        return `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`;
      }
      if (Array.isArray(value)) {
        return value
          .map((v) => `${encodeURIComponent(key)}[]=${encodeURIComponent(v.toString())}`)
          .join('&');
      }
      if (typeof value === 'object') {
        return serialize({ [key]: value });
      }
      return '';
    })
    .filter((part) => part !== '')
    .join('&');
}
