import axios, { AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';
import { HOST_API } from 'src/config-global';

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken') ?? Cookies.get('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const post = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.post(url, { ...config });

  return res.data;
};

export const endpoints = {
  user: {
    update: '/v1/users/me/update-profile',
    exists: '/v1/users/exists',
  },
  devicePushTokens: {
    upsert: '/v1/device_push_tokens',
  },
  auth: {
    me: '/v1/users/me',
    profilePictures: '/v1/me/pictures',
    updateInfo: '/v1/users/me/update-profile',
    phoneVerification: '/v1/users/phone-verification',
    phoneVerifyCode: '/v1/users/check-verification-code',
    login: '/v1/auth/login',
    register: '/v1/auth/register',
    registerInfo: '/v1/auth/signup',
    updatePictures: `/v1/users/me/pictures`,
    resetPassword: '/v1/auth/reset-password',
    socialSignUp: '/v1/auth/social-signup',
    manualLinking: '/v1/auth/manual-linking',
  },
  organization: {
    getPage: (name: string) => `/v1/organizations/${name}/page`,
    getSubscribe: (name: string) => `/v1/organizations/${name}/is_subscribed`,
  },
  subs: '/v1/subs',
  event: {
    list: '/v1/events',
    live: '/v1/events/live',
    count: '/v1/events/count',
    detail: (id: string) => `/v1/events/${id}`,
    update: (id: string) => `/v1/events/${id}`,
    detailPublic: (id: string) => `/v1/events/${id}/public`,
    create: '/v1/events',
    submit: '/v1/submitted-events',
    updateCover: (id: string) => `/v1/events/${id}/cover`, // PUT
    deleteCover: (id: string) => `/v1/events/${id}/cover`, // DELETE
    updatePictures: (id: string) => `/v1/events/${id}/pictures`, // PUT
    attended: '/v1/events/me/attended',
    submitted: '/v1/events/me/submitted',
    subscribedEvents: '/v1/subscribed_events',
  },
  eventRequests: {
    sendRequest: (id: string) => `/v1/events/${id}/event_requests`,
  },
  connect: {
    list: (id: string) => `/v1/connect/${id}/attendees`,
    likes: '/v1/connect/requests',
    likesCount: '/v1/connect/requests/count',
    request: (id: string) => `/v1/connect/${id}/request`,
    participate: (id: string) => `/v1/events/${id}/participants`,
    updateAttendance: (id: string) => `/v1/events/${id}/participants`,
    channel: (id: string) => `/v1/connect/matches/${id}`,
    matches: {
      getMatches: '/v1/connect/matches',
      showAvatar: (id: string) => `/v1/connect/matches/${id}/reveal`,
      unMatch: (id: string) => `/v1/connect/matches/${id}/unmatch`,
    },
  },
  orders: {
    processOrder: '/v1/orders',
    getById: (orderId: string) => `/v1/orders/${orderId}`,
    getByCartId: (cartId: string) => `/v1/orders/carts/${cartId}`,
    processFreeOrder: (cartId: string) => `/v1/orders/carts/${cartId}`,
  },
  cities: {
    findAll: '/v1/cities',
  },
  cart: {
    createCart: (eventId: number) => `/v2/events/${eventId}/carts`,
    updateCart: (eventId: number, id: number) => `/v2/events/${eventId}/carts/${id}`,
    getCart: (cartId: string) => `/v1/carts/${cartId}`,
  },
  contact: (orgId: number) => `/v1/contacts/organizations/${orgId}`,
  promoCode: {
    findByNameAndEventId: (eventId: string, name: string) =>
      `/v1/events/${eventId}/promocodes/${name}`,
  },
  tickets: {
    findOne: (id: string) => `/v1/tickets/${id}`,
    getByEventId: (id: string) => `/v1/tickets/events/${id}`,
    getTicketsGroupedByEvent: `/v1/events/me/tickets`,
    transferTicket: `/v1/transferred_tickets`,
    deleteTransferredTicket: (ticketId: number, id: number) =>
      `v1/tickets/${ticketId}/transferred_tickets/${id}`,
  },
  shopItems: {
    findAll: (eventId: number) => `/v1/shops/events/${eventId}/items`,
  },
};

export { axiosInstance };
